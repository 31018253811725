<script>
import NavBar from './components/NavBar.vue'
import Intro from './components/Intro.vue'

export default {
  name: 'App',
  components: {
    NavBar,
    Intro,
  }
}
</script>

<template>
  <img src='./assets/logo.svg' alt='website logo'>
  <NavBar />
  <Intro />
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
